<template>
<div>
<van-nav-bar title="授權商務卡片編輯" right-text="關閉" @click-right="$router.push('/')" />
<van-form @submit="onSubmit">
  <van-cell-group inset>
    <van-field
      v-model="form.user_id"
      label="會員編號"
      name="pattern"
      placeholder="請輸入想授權的會員編號"
      label-width="100"
     :rules="[{ required: true, message: '會員編號為必填' }]"
    />
    <van-field
      v-model="form.a_hour"
      label="授權時間(小時)"
      name="pattern"
      placeholder="請輸入想授權的時間"
      label-width="100"
     :rules="[{ required: true, message: '授權時間為必填' }]"
    />
  </van-cell-group>
  <div style="margin: 16px;">
    <van-button round block type="primary" native-type="submit">
      送出
    </van-button>
  </div>
</van-form>
<table id="auth-list">
  <tr>
    <th>授權會員</th>
    <th>授權時間</th>
    <th>操作</th>
  </tr>
  <tr v-for="v of authList" :key="v.id">
    <td>{{v.user_id}}</td>
    <td>{{v.auth_time}}</td>
    <td @click="handleDelete(v.id)">刪除</td>
  </tr>
</table>
</div>
</template>

<script setup>
import { onMounted, ref } from 'vue'

import { Toast } from 'vant';

import { setAuthUser , delAuthUser , getAuthUsers } from '@/api'

const form = ref({
  user_id: '',
  a_hour: 1
})

const onSubmit = async () =>{
  let res = await setAuthUser(form.value);
  
  if(res.code!==200){
    return Toast.fail('授權失敗,'+res.data);
  }

  genAuthList();

  return Toast.success('授權成功');
}

//授權清單
const authList = ref([]);


const genAuthList = async()=>{
  let res = await getAuthUsers();

  if(res.code===200){
    authList.value = res.data;
  }
}

const handleDelete = async (id)=>{
  
  let res = await delAuthUser(id);

  console.log(res)

  if(res.code!==200){
    return Toast.fail('刪除失敗');
  }

  genAuthList();

  return Toast.success('刪除成功');

}

onMounted(()=>{
  genAuthList();
})

</script>

<style lang="less" scoped>
#auth-list{
  width: 100%;
  td,th{
    border: 1px solid #ddd;
    padding: 3px;
  }
  tr:nth-child(even){
    background-color: #f2f2f2;
  }
}
</style>